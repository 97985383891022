/**
 * The defaultAccordionItemData contains all data needed to hydrate an Accordion Item view
 *
 * @typedef {Object}          AccordionItem
 *
 * @property {String}         id                  - id
 * @property {String}         icon                - Accordion item icon
 * @property {String}         size                - Accordion size
 * @property {String}         title               - Accordion title
 * @property {String}         subtitle            - Accordion subtitle
 * @property {Boolean}        isOpened            - Is initially opened
 */

import { defaultCollapseData, CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { defaultCollapseTriggerData, CollapseTriggerTemplate } from '../../components/collapse/c-collapse__trigger.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-accordion__tokens.json')['c-accordion']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/accordion/data/c-accordion__tokens.json`)['c-accordion'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultAccordionItemData = {
  id: 0,
  icon: '',
  size: '',
  title: '',
  subtitle: '',
  isOpened: false,
  track: '',
  collapseAttributes: '',
  ariaLabelTextForArrow: ''
}

export const AccordionItemTemplate = (d) => {
  d = { ...defaultAccordionItemData, ...d }

  return `<div class="c-accordion__item">
  <div class="c-accordion__item-heading" data-c-collapse__id="${d.id}" data-c-collapse__action="toggle">
    ${d.icon ? '<span class="m-icon m-icon--' + d.icon + ' c-accordion__item-title-icon"></span>' : ''}
    <div class="c-accordion__item-title ${getTokenClass('title__material', d.size || 'default', tokens)}">${d.title}</div>
    ${d.subtitle && !d.size ? '<div class="c-accordion__item-subtitle ' + getTokenClass('subtitle__material', d.size || 'default', tokens) + '">' + d.subtitle + '</div>' : ''}
    ${CollapseTriggerTemplate({ ...defaultCollapseTriggerData, id: d.id, text: '', textPattern: null, arrow: true, smooth: true, extraClasses: 'c-accordion__item-trigger', ariaLabel: d.ariaLabelTextForArrow })}
  </div>
  ${CollapseTemplate(Object.assign({}, defaultCollapseData, {
    id: d.id,
    opened: d.isOpened,
    maxItems: null,
    childrenWrapper: null,
    attributes: d.collapseAttributes,
    extraClasses: 'c-accordion__item-collapse',
    track: d.track,
    html: `
    <div class="c-accordion__item-content ${getTokenClass('content__material', d.size || 'default', tokens)}">
      ${d.innerHTML ? `${d.innerHTML}` : ''}
    </div>
    `
  }))}
</div>`
}
