import {
  getMin as getMinDate,
  getMax as getMaxDate,
  dateToString,
  getConsecutivePeriod,
  formatDate

} from '../../../js/helpers/dates'

import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'

import CalendarDataModel from './calendar-data-model'
import Flatpickr from 'flatpickr'

const EventEmitter = require('eventemitter3')

const componentAPI = 'c-calendar'
const ComponentContainer = `${componentAPI}__container`
const ComponentInput = `${componentAPI}__input`

const flatpickrLocales = register(`window.sundio.i18n.${language}.flatpickr`)
const dateLocales = register(`window.sundio.i18n.${language}.dates`)

/**
 * A calendar
 */
export default class Calendar {
  /**
   * Creates a new calendar.
   * @constructor
   * @param {HTMLElement} element - The HTML component element.
   * @param {CalendarDataModel|Object} [calendarData={}] - The calendar data.
   * @param {Object} [flatpickrOptions={}] - The options for flatpikr instance.
   */
  constructor (element, calendarData = {}, flatpickrOptions = {}, parentInstance = {}) {
    this.element = element
    this.events = new EventEmitter()
    this.input = this.element.querySelector(`[data-js-api="${ComponentInput}"]`)
    if (!this.input) return
    this.container = this.element.querySelector(`[data-js-api="${ComponentContainer}"]`)
    this.calendarDataModel = calendarData instanceof CalendarDataModel ? calendarData : new CalendarDataModel(calendarData)
    this.calendarDataModel.events.on('change', this.updateFlatpickr, this)
    this.flatpickr = this.initFlatpickr(flatpickrOptions)
    element[componentAPI] = {
      element: this.element,
      input: this.input,
      events: this.events,
      calendarDataModel: this.calendarDataModel,
      updateCalendarData: (this.updateCalendarData).bind(this),
      jumpToDate: (this.jumpToDate).bind(this),
      setSelectionPreprocessingCallback: (this.setSelectionPreprocessingCallback).bind(this)
    }
    this.parentInstance = parentInstance
  }

  /**
   * Updates the calendar data on both component an flatpickr
   * @param {CalendarDataModel|Object} calendarData={} - The calendar data.
   *
   * @returns {Calendar} Self instance.
   */
  updateCalendarData (calendarData = {}) {
    this.calendarDataModel = calendarData instanceof CalendarDataModel ? calendarData : new CalendarDataModel(calendarData)
    this.updateFlatpickr()
    this.calendarDataModel.events.on('change', this.updateFlatpickr, this)
    return this
  }

  /**
   * Just a flatpickr function wrapper
   *
   * @param {DateString|Date} date
   *
   * @returns {Calendar} Self instance.
   */
  jumpToDate (date) {
    this.flatpickr.jumpToDate(date)
    return this
  }

  /**
   * Initializes the Flatpickr calendar.
   * @param {Object} [flatpickrOptions={}] - The options for flatpickr instance.
   * @returns {Flatpickr} The Flatpickr calendar instance.
   */
  initFlatpickr (flatpickrOptions = {}) {
    const defaultOptions = {
      mode: 'single',
      minDate: this.calendarDataModel.getAttribute('startDate'),
      maxDate: this.calendarDataModel.getAttribute('endDate'),
      inline: true,
      appendTo: this.container || undefined,
      weekNumbers: dateLocales.weekNumbers,
      locale: flatpickrLocales || 'en',
      animate: false, // Animation are disabled in order to fix a visual bug in the month names
      onChange: (selectedDates, dateStr, instance) => this.onCalendarChange(selectedDates, dateStr, instance),
      onDayCreate: (dObj, dStr, fp, dayElem) => this.onDayCreate(dObj, dStr, fp, dayElem)
    }
    defaultOptions.locale.scrollTitle = '' // Overwrite the month title to avoid the tooltip
    const options = { ...defaultOptions, ...flatpickrOptions }
    this.mode = options.mode
    options.defaultDate = this.getCalendarSelectedDatesFromModel()
    return Flatpickr(this.input, options)
  }

  /**
   * Get calendarSelectedDates from model
   * @returns {(Date[]|DateString[])} The dates array ready to populate flatpickr instance, according calendar mode
   */
  getCalendarSelectedDatesFromModel () {
    switch (this.mode) {
      case 'single': {
        const selectedDates = this.calendarDataModel.getAttribute('selectedDates')
        return Array.isArray(selectedDates) ? [selectedDates[0]] : [this.calendarDataModel.getAttribute('selectedDate')]
      }
      case 'multiple':
        return this.calendarDataModel.getAttribute('selectedDates')
      case 'range': {
        const selectedDates = this.calendarDataModel.getAttribute('selectedDates')
        return [getMinDate(selectedDates), getMaxDate(selectedDates)]
      }
    }
  }

  /**
   * Set calendarSelectedDates from calendar to model, according calendar mode (only if differs)
   * @param {Date[]} calendarSelection=[] - The dates array from flatpickr instance
   */
  setCalendarSelectedDatesOnModel (calendarSelection = [], originalDate = undefined) {
    let newValue
    switch (this.mode) {
      case 'single':
        newValue = dateToString(calendarSelection[0])
        if (!newValue || newValue === this.calendarDataModel.getAttribute('selectedDate')) break
        this.calendarDataModel.setAttributes({
          selectedDate: newValue,
          selectedDates: [newValue]
        })
        break
      case 'multiple': {
        newValue = calendarSelection.map(d => dateToString(d))
        if (!newValue || newValue === this.calendarDataModel.getAttribute('selectedDates')) break
        if (originalDate) {
          this.calendarDataModel.setAttributes({
            selectedDate: formatDate(originalDate),
            selectedDates: newValue
          })
        } else {
          this.calendarDataModel.setAttribute('selectedDates', newValue)
        }
        break
      }
      case 'range': {
        const selectedDates = getConsecutivePeriod(calendarSelection[0], calendarSelection[1])
        newValue = selectedDates.map(d => dateToString(d))
        if (!newValue || newValue === this.calendarDataModel.getAttribute('selectedDates')) break
        this.calendarDataModel.setAttribute('selectedDates', newValue)
        break
      }
    }
  }

  /**
   * Update the Flatpickr calendar.
   *
   * @returns {Calendar} self instance
   */
  updateFlatpickr () {
    this.flatpickr.set('minDate', this.calendarDataModel.getAttribute('startDate'))
    this.flatpickr.set('maxDate', this.calendarDataModel.getAttribute('endDate'))
    this.flatpickr.setDate(this.getCalendarSelectedDatesFromModel())
    return this
  }

  /**
   * Hook on changes in the calendar.
   */
  onCalendarChange (selectedDates, dateStr, instance) {
    if (this.mode === 'range' && selectedDates.length < 2) return
    if (this.mode === 'single' && !selectedDates.length) return
    if (selectedDates === undefined || !selectedDates.length) return

    let originalDate
    if (this.selectionPreprocessingCallback) {
      const result = this.selectionPreprocessingCallback(selectedDates, this.parentInstance)
      this.mode = result.mode
      originalDate = result.selectedDate
      selectedDates = result.selectedDates
    }

    this.setCalendarSelectedDatesOnModel(selectedDates, originalDate)
    this.flatpickr.redraw()
  }

  /**
   * Hook on creation of every calendar day.
   */
  onDayCreate (dObj, dStr, fp, dayElem) {
    const currentDateString = dateToString(dayElem.dateObj)
    if (this.calendarDataModel.getAttribute('disabledDates').includes(currentDateString)) { dayElem.className += ' is-disabled ' }
    if (this.mode === 'single' && this.calendarDataModel.getAttribute('selectedDates').includes(currentDateString)) { dayElem.className += ' is-selection-offset ' }
  }

  /**
   * Creates a new calendar for every element on document with targeted attributes.
   * @returns {Calendar[]} The created instances.
   */
  static createInstancesOnDocument () {
    const currentComponents = window.document.querySelectorAll(`[data-js-api="${componentAPI}"]`)
    const instances = []
    for (let i = 0; i < currentComponents.length; i++) {
      instances.push(Calendar.createInstanceOnElement(currentComponents[i]))
    }
    return instances
  }

  /**
   * Creates a new calendar for single element.
   * @param {HTMLElement} element - The targeted HTML element.
   * @param {CalendarDataModel|Object} [calendarData={}] - The calendar data.
   * @returns {Calendar} Self instance.
   */
  static createInstanceOnElement (element, calendarData = {}) {
    return new Calendar(element, calendarData)
  }

  setSelectionPreprocessingCallback (callback = undefined) {
    this.selectionPreprocessingCallback = callback
  }
}
