import Model from '../../../js/core/model'
import { language } from '../../../js/user/locale-settings'
import {
  areDatesInSameMonth,
  areDatesInSameYear,
  areDatesWholeMonth,
  dateStringsToDates,
  getEdgeDates
} from '../../../js/helpers/dates'
import { register } from '../../../js/document/namespace'

/**
 * A calendarData model
 */
export default class CalendarDataModel extends Model {
  /**
   * Creates a new CalendarDataModel
   * @constructor
   * @param {Object} attributes - The model attributes
   * @param {DateString[]} attributes.selectedDates - Selected dates (chosen departure dates)
   * @param {DateString} attributes.selectedDate - Selected date (main departure date on date ranges)
   * @param {DateString[]} attributes.disabledDates - Disabled dates (aka not possible to choose as a departure date)
   * @param {DateString} attributes.startDate - The min date to show (first date in calendar)
   * @param {DateString} attributes.endDate - The max date to show (last date in calendar)
   */

  constructor (attributes = {}) {
    super({
      ...{
        selectedDates: [],
        selectedDate: '',
        disabledDates: [],
        startDate: '',
        endDate: ''
      },
      ...attributes
    })
  }

  /**
   * Returns an humanized string for the selected dates, assuming they're consecutive period
   *
   * @return {String}
  */
  humanizedSelectedDates () {
    const selectedDates = dateStringsToDates(this.getAttribute('selectedDates'))
    // No dates > blank string
    if (selectedDates.length <= 0) { return '' }
    // Single date > DD month
    if (selectedDates.length === 1) { return selectedDates[0].toLocaleString(language, { day: 'numeric', month: 'long', year: 'numeric' }) }
    // Multiple dates
    if (selectedDates.length > 1) {
      const edges = getEdgeDates(selectedDates)
      // Whole month > month YYYY
      if (areDatesWholeMonth(...edges)) { return selectedDates[0].toLocaleString(language, { month: 'long', year: 'numeric' }) }
      const rangeSeparator = register(`window.sundio.i18n.${language}.flatpickr.rangeSeparator`) || ' - '
      // Same month > DD - DD mon.
      if (areDatesInSameMonth(...edges)) {
        const endDay = edges[1].toLocaleString(language, { day: 'numeric' })

        return edges[1].toLocaleString(language, { day: 'numeric', month: 'short', year: 'numeric' })
          .replace(RegExp('(' + endDay + ')'), edges[0].toLocaleString(language, { day: 'numeric' }) + rangeSeparator + '$1')
      } else {
        // Different month > DD mon. - DD mon.
        return edges[0].toLocaleString(language, { day: 'numeric', month: 'short', ...(areDatesInSameYear(...edges) ? {} : { year: 'numeric' }) }) +
          rangeSeparator +
          edges[1].toLocaleString(language, { day: 'numeric', month: 'short', year: 'numeric' })
      }
    }
  }
}
