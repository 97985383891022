import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import box tokens
const tokensShared = require('./data/c-box__tokens.json')['c-box']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/box/data/c-box__tokens.json`)['c-box'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The BoxData contains all data needed to hydrate a Box view
 *
 * @typedef {Object}          BoxData
 *
 * @property {String}         html                - Content of the box
 * @property {String}         [variant]           - Box's variant
 * @property {String}         [extraClasses]      - Extra classes for the button
 * @property {Object}         [attributes]        - Attributes for the button
 *
 */
export const defaultBoxData = {
  html: '',
  variant: 'default',
  extraClasses: '',
  attributes: {}
}

export const BoxTemplate = (d) => {
  d = { ...defaultBoxData, ...d }
  return `
    <div class="c-box o-box 
                ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''} 
                ${d.extraClasses ? d.extraClasses : ''}"
            ${d.attributes ? encodeAttributes(d.attributes) : ''}>
        <div class="c-box__content o-box__content ${getTokenClass('space-stack-children', 'default', tokens)}">
            ${d.html}
        </div>
    </div>
    `
}
