import * as cssHelper from '../../../js/document/css'
import * as namespace from '../../../js/document/namespace'
import * as scrollHelper from '../../../js/document/scroll'

const components = namespace.register('sundio.components')
const componentName = 'backdrop'
const componentClassName = 'c-backdrop'
const componentDesignSystemDifferentiator = 'c-backdrop--ds'
const componentAPI = 'c-backdrop'
const eventPrefix = 'c-backdrop:'
const events = {
  open: new window.CustomEvent(eventPrefix + 'open'),
  opened: new window.CustomEvent(eventPrefix + 'opened'),
  close: new window.CustomEvent(eventPrefix + 'close'),
  closed: new window.CustomEvent(eventPrefix + 'closed')
}
const classNames = {
  opened: 'in',
  opening: 'is-opening',
  closing: 'is-closing'
}

export default function Backdrop (el) {
  const newElement = (!el || !(el instanceof window.HTMLElement))
  let scrollDisabled = false

  if (newElement) {
    el = document.createElement('div')
    el.classList.add(componentClassName, componentDesignSystemDifferentiator)
  }

  if (el[componentAPI] !== undefined) return

  const exposedAPI = {
    element: el,
    open,
    close,
    remove,
    destroy
  }

  el[componentAPI] = exposedAPI
  if (newElement) document.body.appendChild(el)

  return exposedAPI

  function open (done) {
    el.dispatchEvent(events.open)
    el.classList.add(classNames.opening)
    cssHelper.getStyle(el, 'width')
    el.classList.add(classNames.opened)
    disableScroll()
    setTimeout(opened,
      cssHelper.cssTimeToMs(cssHelper.getStyle(el, 'transition-duration')),
      done
    )
  }

  function opened (done) {
    el.classList.remove(classNames.opening)
    el.dispatchEvent(events.opened)
    if (typeof done === 'function') done()
  }

  function close (done) {
    el.dispatchEvent(events.close)
    enableScroll()
    el.classList.add(classNames.closing)
    el.classList.remove(classNames.opened)
    setTimeout(closed,
      cssHelper.cssTimeToMs(cssHelper.getStyle(el, 'transition-duration')),
      done
    )
  }

  function closed (done) {
    el.classList.remove(classNames.closing)
    el.dispatchEvent(events.closed)
    if (typeof done === 'function') done()
  }

  function remove () {
    enableScroll()
    if (newElement) document.body.removeChild(el)
  }

  function destroy () {
    remove()
    el[componentAPI] = null
    delete el[componentAPI]
  }

  function disableScroll () {
    if (!scrollDisabled) {
      scrollHelper.disable(el)
      scrollDisabled = !scrollDisabled
    }
  }

  function enableScroll () {
    if (scrollDisabled) {
      scrollHelper.enable(el)
      scrollDisabled = !scrollDisabled
    }
  }
}

components[componentName] = Backdrop
