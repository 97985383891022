import { debounce } from '../../../js/utils'
import Collection from '../../../js/core/collection'
import { forceRepaint, getInnerHeight, getStyle } from '../../../js/document/css'
import { smooth as scrollSmooth } from '../../../js/document/scroll'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { elementFromString } from '../../../js/document/html-helper'
import FloatingBox from '../floating-box/main'
import { getAbsoluteUrl } from '../../../js/document/url'
import { fetch as whatwgFetch } from 'whatwg-fetch'
import { LoaderTemplate } from '../loader/c-loader.template'
import breakpoints from '../../../js/document/breakpoints'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import registeredEvents from '../../../js/helpers/registered-events'
import { autocompleteEvents } from '../../../js/document/event-types'
import Component from '../../../js/core/component/component'
import { encodeHtml } from '../../../js/helpers/string'

const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.autocomplete`)

const abortableFetch = window.Request && 'signal' in new window.Request('') ? window.fetch : whatwgFetch
const EventEmitter = require('eventemitter3')

// Ensure other child component APIs are loaded on time
require('../textbox/main')

const componentAPI = 'c-autocomplete'

const componentQueries = {
  textBox: `[data-${componentAPI}__textbox]`,
  floatsFrom: `data-${componentAPI}__floats-from`,
  forceOpenUntil: `data-${componentAPI}__force-open-until`,
  track: 'data-track'
}

const Diacritics = require('diacritic')

/**
 * The AutocompleteSearch contains all data related with search term and given results
 *
 * @typedef {Object}          AutocompleteSearch
 *
 * @property {String}         term          - The escaped term used on search
 * @property {(RegExp|null)}  termRegExp    - The RegExp build from term
 * @property {Model[]}        results       - Results matching term and searchAttributes
 * @property {Model[]}        resultsShown  - Subset or alternate results to be displayed
 */

/**
 * The AutocompleteSelection contains all data related with selected stuff
 *
 * @typedef {Object}              AutocompleteSelection
 *
 * @property {String}             text            - The text used on input field
 * @property {(Model|null)}       model           - The matching model if there's any
 * @property {(HTMLElement|null)} resultElement   - The matching result element if there's any
 */

/**
 * The AutocompleteOptions contains all configuration to build an instance
 *
 * @typedef {Object}            AutocompleteOptions
 *
 * @property {Collection}       data                    - The data to search for
 * @property {Boolean}          [filterAndSort]         - If true, will filter and sort the data with the current selection
 * @property {String[]}         searchAttributes        - The model attributes where to search for
 * @property {AutocompleteSelection} [selection]        - The current selection
 * @property {Integer}          [delay]                 - The delay in ms to perform a search after typing chars
 * @property {Integer}          [minChars]              - The min number of chars to perform a search
 * @property {(Integer|null)}   [maxResults]            - The max number of results to display from matching models
 * @property {String[]}         [displayAttributes]     - The model attributes to be shown on results
 * @property {Boolean}          [emptyShownResults]     - If true, will show results when input is empty & focused
 * @property {(Model[]|null)}   [emptyShownResultsData] - The models to display when input is empty & focused
 * @property {Boolean}          [cache]                 - If true, will cache searches to improve performance
 * @property {String}           [minHeight]             - The minimum height of searchResults element when it overflows bottom viewport edge
 * @property {String}           [maxHeight]             - The maxHeight height of searchResults element
 * @property {String}           [floatsFrom]            - The breakpoint when floating box will start to float
 * @property {String}           [forceOpenUntil]        - The breakpoint until floating box will open before typing anything, just by clicking
 * @property {String}           [resultClassName]       - The class name used to identify a result into searchResults element
 * @property {String}           [selectedClassName]     - The class name used to mark result into selected state
 * @property {String}           [url]                   - The url used for live asynchronous searches via API, for each input change. Needs [urlSearchParam] to be configured as well.
 * @property {String}           [urlSearchParam]        - The query string parameter used for searches via API, for sending the current selection. Needs [url] to be configured as well.
 * @property {Object}           [urlConfig]             - Additional request configuration to use when calling API
 */
const defaults = {
  data: new Collection([]),
  url: null,
  urlSearchParam: null,
  urlParams: {},
  urlConfig: {
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  },
  filterAndSort: true,
  searchAttributes: [],
  selection: null,
  delay: 150,
  minChars: 1,
  maxResults: null,
  displayAttributes: [],
  emptyShownResults: true,
  emptyShownResultsData: null,
  cache: true,
  minHeight: '120px',
  maxHeight: '400px',
  floatsFrom: 'xs',
  forceOpenUntil: null,
  resultClassName: 'c-autocomplete__result',
  selectedClassName: 'is-selected'
}

/**
 * Autocomplete
 */
export default class Autocomplete {
  /**
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {AutocompleteOptions} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.events = new EventEmitter()
    this.options = { ...defaults, ...options }

    // Override some options from element attributes
    this.options.floatsFrom = this.element.getAttribute(componentQueries.floatsFrom) || this.options.floatsFrom
    this.options.forceOpenUntil =
      this.element.getAttribute(componentQueries.forceOpenUntil) || this.options.forceOpenUntil

    // Get the local strings
    this.locales = this._getLocales()

    // Run the queries finding for elements & access to available API
    this.textBox = this.element.querySelector(componentQueries.textBox)
    this.textBoxApi = this.textBox['c-textbox']
    this.textBoxInput = this.textBox.querySelector('input')

    // Recover a selection if it's given on options
    if (this.options.selection && this.options.selection.model) {
      this.selection = this.selection || this.options.selection
      this.setSelectionFromModel(this.options.selection.model, this.selection).updateInputValueFromSelection(
        this.selection
      )
    }

    // Setup initial selection & search objects
    this.selection = this.selection || this.newSelectionFromInput()
    this.tempSelection = null
    this.currentSearch = null
    this.searchCache = this.options.cache ? {} : undefined
    this.dataCache = this.options.cache ? {} : undefined

    // Debounced input changes function
    this.debouncedInputChange = debounce(() => this.onInputChange(), this.options.delay)

    // Bind child component events
    this.textBoxApi.events.on('keydown', this.onKeyDown, this)
    this.textBoxApi.events.on('keyup', this.onKeyUp, this)
    if (!this.options.disableTextBoxOnBlur) this.textBoxApi.events.on('blur', this.onBlur, this)
    this.textBoxApi.events.on('focus', this.onFocus, this)
    this.textBoxApi.events.on('clear', this.resetSelection, this)

    // Expose component public API
    element[componentAPI] = {
      element: this.element,
      events: this.events,
      textBox: this.textBox,
      setOptions: this.setOptions.bind(this),
      remove: this.remove.bind(this)
    }

    registeredEvents.registerComponentEvents(componentAPI, this.events, {
      ...(this.element.hasAttribute(componentQueries.track) && {
        track: this.element.attributes[componentQueries.track].value
      })
    })
  }

  /**
   * Set or updates the current options
   *
   * @param {AutocompleteOptions} newOptions
   *
   * @returns {Autocomplete} Self instance
   */
  setOptions (newOptions) {
    this.options = { ...this.options, ...newOptions }
    // Recover a selection if it's given on options
    if (this.options.selection) {
      this.setSelectionFromModel(this.options.selection.model, this.options.selection).updateInputValueFromSelection(
        this.options.selection
      )
    }
    this.searchCache = this.options.cache ? {} : undefined
    this.dataCache = this.options.cache ? {} : undefined
    return this
  }

  /**
   * Removes the component instance
   */
  remove () {
    domEventsHelper.attachEvents(this.resultsElementEvents, componentAPI)
    this.removeResultsElement()
    delete this.resultsElement
    delete this.element[componentAPI]
  }

  /**
   * Return a locale strings object
   */
  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return { ...globalLocales, ...componentLocales, ...(customLocaleData || {}) }
  }

  /*
   * -----------------------------------------------------
   * SELECTION RELATED METHODS
   * -----------------------------------------------------
   * */

  /**
   * Returns a new selection based on input value
   *
   * @returns {AutocompleteSelection}
   */
  newSelectionFromInput () {
    const min = this.isAsyncSearch() ? this.options.asyncSearchMinChars : this.options.minChars
    const text = this.textBoxInput.value.length >= min ? this.textBoxInput.value.trim() : ''
    return this.newSelectionFromText(text)
  }

  /**
   * Returns a new selection based on given text
   *
   * @param {String} text
   *
   * @returns {AutocompleteSelection}
   */
  newSelectionFromText (text) {
    return {
      text,
      model: this.options.data.findWhere(this.options.searchAttributes[0], text) || null,
      resultElement: null
    }
  }

  /**
   * Returns a new selection based on given model
   *
   * @param {Model} model - The selected model
   *
   * @returns {AutocompleteSelection}
   */
  newSelectionFromModel (model) {
    return {
      text: model.getAttribute(this.options.searchAttributes[0]).toString(),
      model,
      resultElement: null
    }
  }

  /**
   * Sets Selection from certain model
   *
   * @param {Model} model - The selected model
   * @param {AutocompleteSelection} [selection] - The selected model
   *
   * @returns {Autocomplete} Self instance
   */
  setSelectionFromModel (model, selection = this.tempSelection) {
    if (selection && selection.model === model) return this
    selection = model
      ? this.newSelectionFromModel(model)
      : {
          text: '',
          model: null,
          resultElement: null
        }
    return this
  }

  /**
   * Find and set resultElement from the given selection
   * - Also change element state to selected, by adding the selected className
   *
   * @param {AutocompleteSelection} [selection] - The selection object to set into
   *
   * @returns {Autocomplete} self instance
   */
  setSelectionResultElement (selection = this.tempSelection) {
    if (!selection || !selection.model) return this
    const resultElement = this.resultsElement.querySelector(`[data-cid="${selection.model.cid}"]`) || null
    if (!resultElement) return this
    resultElement.classList.add(this.options.selectedClassName)
    selection.resultElement = resultElement
    return this
  }

  /**
   * Unset resultElement and it's selected state from the given selection
   *
   * @param {AutocompleteSelection} [selection] - The selection object to unset from
   *
   * @returns {Autocomplete} self instance
   */
  unsetSelectionResultElement (selection = this.tempSelection) {
    if (selection.resultElement) selection.resultElement.classList.remove(this.options.selectedClassName)
    selection.resultElement = null
    return this
  }

  /**
   * Updates the input value with a given selection
   *
   * @param {AutocompleteSelection} [selection]
   *
   * @returns {Autocomplete} Self instance
   */
  updateInputValueFromSelection (selection = this.tempSelection) {
    this.textBoxApi.setProp(
      'value',
      selection.model ? selection.model.getAttribute(this.options.searchAttributes[0]) : ''
    )
    return this
  }

  /*
   * -----------------------------------------------------
   * SEARCH RELATED METHODS
   * -----------------------------------------------------
   * */

  /**
   * Returns true if searches are asynchronous via API
   *
   * @returns {Boolean}
   */
  isAsyncSearch () {
    return this.options.url && this.options.urlSearchParam
  }

  /**
   * Returns a new blank search with or without resultsShown according emptyShownResults option & data
   *
   * @returns {AutocompleteSearch}
   */
  newEmptySearch () {
    return {
      term: '',
      termRegExp: null,
      results: [],
      resultsShown:
        this.options.emptyShownResults && this.options.emptyShownResultsData.length
          ? this.options.emptyShownResultsData
          : []
    }
  }

  /**
   * Get current results based on currentSearch
   * This method can be overridden on instances if you want custom search
   *
   * @param {RegExp} termRegExp - The regExp to match attributes
   *
   * @returns {Array} The matching results
   */
  getResults (termRegExp) {
    // Search for results matching regExp on every searchAttribute
    const matchResults = []
    this.options.searchAttributes.forEach(attr => {
      this.options.data.models
        .filter(model => Diacritics.clean(model.attributes[attr]).match(termRegExp))
        .forEach(model => {
          if (!matchResults.includes(model)) matchResults.push(model)
        })
    })

    // Compute match length (in chars) for FIRST searchAttribute on matchResults
    // Note: by pre calculating match lengths, sorting will be significantly faster
    const matchLengths = {}
    const sum = (...args) => [...args].reduce((a, b) => a + b, 0)
    const match = str => {
      return str ? str.match(termRegExp) || [] : []
    }
    const matchLength = str => sum(...match(str).map(m => m.length))
    matchResults.forEach(result => {
      matchLengths[result.cid] = matchLength(result.getAttribute(this.options.searchAttributes[0]))
    })

    // Return matchResults sorted by matchLengths (more coincidences goes first)
    return matchResults.sort((a, b) => {
      const aLength = matchLengths[a.cid]
      const bLength = matchLengths[b.cid]
      return aLength < bLength ? 1 : aLength > bLength ? -1 : 0
    })
  }

  /**
   * Returns a search based on given text
   * - Uses the cache (if enabled) to retrieve & store searches
   *
   * @param {String} text
   *
   * @returns {AutocompleteSearch}
   */
  async getSearchFromText (text) {
    // Try to recover the search from cache, if it's enabled
    if (this.options.cache && this.searchCache[text]) {
      if (this.isAsyncSearch()) this.options.data = this.dataCache[text]
      return this.searchCache[text]
    }

    // Prepare the text and regExp
    const safeText = this._getSafeText(text)
    const sanitizedText = this._getSafeText(Diacritics.clean(text))
    const regExp = this._getRegExp(safeText)
    const sanitizedRegExp = this._getRegExp(sanitizedText)

    // Update data if asynchronous
    if (this.isAsyncSearch()) {
      try {
        this.options.data = await this.getFreshData(text)
      } catch (error) {
        this.asyncSearchInProgress = false
        throw error
      }
      this.asyncSearchInProgress = false
    }

    // Get the matching results
    const results = sanitizedRegExp
      ? this.options.filterAndSort
        ? this.getResults(sanitizedRegExp)
        : this.options.data.models
      : []
    const resultsShown = text
      ? this.options.maxResults
        ? results.slice(0, this.options.maxResults)
        : results
      : this.options.emptyShownResults
        ? this.options.emptyShownResultsData || this.options.data.models
        : []

    // Cache and return currentSearch
    const search = {
      term: safeText,
      termRegExp: regExp,
      results,
      resultsShown
    }
    if (this.options.cache) this.searchCache[text] = search
    if (this.options.cache && this.isAsyncSearch()) this.dataCache[text] = this.options.data
    return search
  }

  /**
   * Updates data collection with fresh data gotten from an API
   *
   * @param {String} text
   *
   * @returns {AutocompleteSearch}
   */
  async getFreshData (text) {
    if (this.abortController) this.abortController.abort()
    this.abortController = new window.AbortController()

    const requestUrl = new window.URL(getAbsoluteUrl(this.options.url))
    Object.entries(this.options.urlParams).forEach(([paramName, paramValue]) => {
      requestUrl.searchParams.append(paramName, paramValue)
    })

    requestUrl.searchParams.append(this.options.urlSearchParam, text)

    const freshData = await abortableFetch(requestUrl.href, {
      signal: this.abortController.signal,
      ...this.options.urlConfig
    })
      .then(response => response.json())
      .catch(error => {
        throw new Error(error)
      })
    this.abortController = null
    return new Collection(this.processFreshData(freshData))
  }

  /**
   * Process fresh data
   * Sometimes the API response has a different schema than what we expect
   * This method will be called AFTER a fetch and BEFORE populating the data collection
   * It can also be overridden per instance
   *
   * @param {*} data
   *
   * @returns {*} processed data
   */
  processFreshData (data) {
    return data
  }

  /*
   * -----------------------------------------------------
   * RESULTS RELATED METHODS
   * -----------------------------------------------------
   * */

  /**
   * Update current results based on currentSearch & tempSelection
   *
   * - Remove results element & break if:
   *   - CurrentSearch is falsely
   *   - There's no term to search
   *     && There's no results to show
   *     && no asyncSearch is running
   *
   * - Append results element (if not appended before)
   * - Set selection result element
   * - Update results scroll
   *
   * @returns {Autocomplete} Self instance
   */
  updateResults () {
    const forceOpen = this.options.forceOpenUntil
      ? window.innerWidth < breakpoints[this.options.forceOpenUntil]
      : false
    if (
      (!this.currentSearch && !forceOpen) ||
      (!forceOpen && !this.currentSearch.term && !this.currentSearch.resultsShown.length && !this.asyncSearchInProgress)
    ) {
      this.removeResultsElement()
      return this
    }

    return this.appendResultsElement()
      .setSelectionResultElement()
      .updateResultsScroll()
  }

  /**
   * Update results scroll based on currentSearch & tempSelection
   *
   * - Adjust results element scroll to top or selected resultElement
   *
   * @returns {Autocomplete} Self instance
   */
  updateResultsScroll () {
    if (this.tempSelection && this.currentSearch.resultsShown.includes(this.tempSelection.model)) {
      this.ensureResultVisibility()
    } else {
      this.resultsScrollElement.scrollTop = 0
    }
    return this
  }

  /**
   * Fixes the resultsElement scroll to allocate the given selection resultElement
   *
   * @param {AutocompleteSelection} [selection]
   *
   * @returns {Autocomplete} self instance
   */
  ensureResultVisibility (selection = this.tempSelection) {
    if (!selection.resultElement) return this

    // Get the visible ang height ranges for affected elements
    const resultsInnerHeight = getInnerHeight(this.resultsScrollElement)
    const safeMargin = 20
    const resultsVisibleRange = [
      this.resultsScrollElement.scrollTop,
      this.resultsScrollElement.scrollTop + resultsInnerHeight
    ]
    const resultHeightRange = [
      selection.resultElement.offsetTop,
      selection.resultElement.offsetTop + selection.resultElement.offsetHeight
    ]

    // Fixes the scroll if overflows to top
    if (resultHeightRange[0] < resultsVisibleRange[0]) {
      scrollSmooth(this.resultsScrollElement, 0, resultHeightRange[0] - safeMargin)
    }

    // Fixes the scroll if overflows to bottom
    if (resultHeightRange[1] > resultsVisibleRange[1]) {
      scrollSmooth(this.resultsScrollElement, 0, resultHeightRange[1] - resultsInnerHeight + safeMargin)
    }

    return this
  }

  /**
   * Appends the current resultsElement
   *
   * - Creates resultsElement if there's none, and attach mouse events into it
   * - Fills resultsElement with...
   *   - Current Search results (if there's any)
   *   - Searching for results (if there's an async search in progress)
   *   - No results found (on other cases)
   * - Append resultsElement (if was not connected)
   * - Levitate textBox (if needed)
   * - Open or update resultsElement (floatingBox)
   * - Emit OPEN event
   *
   * @returns {Autocomplete} self instance
   */
  appendResultsElement () {
    if (!this.resultsElement) {
      this.resultsElement = elementFromString(this.getResultsElementHtml())
      this.resultsElementApi = new FloatingBox(this.resultsElement)
      this.attachEvents(this.resultsElement)
    }

    this.currentSearch = this.currentSearch || this.newEmptySearch()
    this.resultsScrollElement = this.resultsElement.querySelector('.c-floating-box__body-content')
    this.resultsScrollElement.innerHTML =
      this.currentSearch.results && this.currentSearch.results.length
        ? this.getResultsHtml().trim()
        : this.asyncSearchInProgress
          ? this.getSearchingResultsHtml().trim()
          : this.getNoResultsHtml().trim()

    if (!this.resultsElement.isConnected) {
      this.textBoxInput.parentElement.appendChild(this.resultsElement)
    }

    this.levitateTextbox()

    this.resultsElementApi.getProp('opened')
      ? this.resultsElementApi.adjustSize()
      : this.resultsElementApi.setProp('opened', true)

    this.events.emit('open')

    Component.initDocumentComponentsFromAPI(this.resultsElement)
    Component.initComponentActionElements(this.resultsElement)

    return this
  }

  /**
   * Removes the current resultsElement
   *
   * - Return the input field to it's original position
   * - Close the resultsElement (floatingBox)
   * - Emits CLOSE event
   *
   * @returns {Autocomplete} self instance
   */
  removeResultsElement () {
    if (!this.resultsElement || !this.resultsElementApi) return this

    this.unLevitateTextbox()
    this.resultsElementApi.setProp('opened', false)
    this.events.emit('close')

    return this
  }

  /**
   * Moves the textBox field to top of the screen (if needed)
   *
   * @returns {Autocomplete} self instance
   */
  levitateTextbox () {
    if (this.textBoxLevitating || getStyle(this.resultsElement, 'position') !== 'fixed') {
      return this
    }
    this.textBoxLevitating = true
    const inputMargin = 20
    const textBoxInputRect = this.textBoxInput.getBoundingClientRect()
    const textBoxInputParentRect = this.textBoxInput.parentElement.getBoundingClientRect()
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth
    // Set the initial style
    this.textBoxInputInitialStyle = {
      width: `${textBoxInputRect.width}px`,
      position: 'fixed',
      left: `${textBoxInputRect.x}px`,
      top: `${textBoxInputRect.y}px`,
      height: `${textBoxInputRect.height}px`,
      zIndex: parseInt(getStyle(this.resultsElement, 'z-index')) + 1
    }
    Object.keys(this.textBoxInputInitialStyle).forEach(k => {
      this.textBoxInput.style[k] = this.textBoxInputInitialStyle[k]
    })
    this.textBoxInput.parentElement.style.height = `${textBoxInputParentRect.height}px`
    forceRepaint(this.textBoxInput)
    // Add transition
    this.textBoxInput.style.transitionDuration = getStyle(this.resultsElement, 'transition-duration')
    this.textBoxInput.style.transitionTimingFunction = 'cubic-bezier(.07, .28, .32, 1.22)'
    this.textBoxInput.style.transitionProperty = 'all'
    forceRepaint(this.textBoxInput)
    // Apply final style
    this.textBoxInput.style.top = `${inputMargin}px`
    this.textBoxInput.style.left = `${inputMargin}px`
    this.textBoxInput.style.width = `${viewportWidth - inputMargin * 2}px`
    this.resultsElement.style.paddingTop = `${textBoxInputRect.height + -(inputMargin * 2)}px`
    return this
  }

  /**
   * Moves the textBox field to the original position (if was moved)
   *
   * @returns {Autocomplete} self instance
   */
  unLevitateTextbox () {
    if (!this.textBoxLevitating) return this
    this.textBoxLevitating = false
    this.textBoxInput.style.transitionTimingFunction = 'cubic-bezier(.38, -.4, .88, .65)'
    Object.keys(this.textBoxInputInitialStyle).forEach(k => {
      this.textBoxInput.style[k] = this.textBoxInputInitialStyle[k]
    })
    const deleteCustomStyles = () => {
      [
        'position',
        'left',
        'top',
        'width',
        'height',
        'z-index',
        'transition-duration',
        'transition-timing-function',
        'transition-property'
      ].forEach(p => {
        this.textBoxInput.style[p] = null
      })
      this.resultsElement.style.paddingTop = null
      this.textBoxInput.parentElement.style.height = null
      this.textBoxInput.removeEventListener('transitionend', deleteCustomStyles, false)
    }
    this.textBoxInput.addEventListener('transitionend', deleteCustomStyles, false)
    return this
  }

  /**
   * Returns a model based on given resultElement
   *
   * @param {HTMLElement} resultElement
   *
   * @returns {(Model|null)}
   */
  getModelFromResultElement (resultElement) {
    if (!resultElement) return null
    const modelCid = resultElement.getAttribute('data-cid')
    return this.options.data.findWhere('cid', modelCid) || null
  }

  /*
   * -----------------------------------------------------
   * EVENTS RELATED METHODS
   * -----------------------------------------------------
   * */

  /**
   * Handled by focus on input
   *
   * - Creates tempSelection
   * - Creates currentSearch
   * - Emit FOCUS
   * - Updates results
   */
  onFocus (ev) {
    if (!this.resultsElementApi || !this.resultsElementApi.getProp('opened')) {
      this.tempSelection = { ...this.selection }
      if (!this.resultsElementApi) {
        this.currentSearch = this.newEmptySearch()
      }
    }
    this.asyncSearchInProgress = false
    this.events.emit('focus')
    this.updateResults()
  }

  /**
   * Handle changes on input value
   *
   * - Updates tempSelection
   * - Updates currentSearch
   * - Updates results
   * - Emit CHANGE
   *
   * !! For performance reasons, consider to DEBOUNCE it !!
   */
  async onInputChange () {
    // Get a newSelection object from input text, and break if it's the same as tempSelection
    const newSelection = this.newSelectionFromInput()
    if (this.tempSelection && newSelection.text === this.tempSelection.text) return

    // Assign the newSelection to tempSelection
    this.tempSelection = newSelection

    // If there's no text on selection...

    if (!this.tempSelection.text) {
      // currentSearch should be empty
      this.currentSearch = this.newEmptySearch()
      // asyncSearchInProgress should be false
      if (this.isAsyncSearch()) this.asyncSearchInProgress = false
      this.updateResults()
    } else {
      // Assume there's text on selection...

      // If the search is async & (there's no cache || there's no cached results)...
      if (this.isAsyncSearch() && (!this.options.cache || !this.searchCache[this.tempSelection.text])) {
        // currentSearch should be empty, asyncSearchInProgress should be true
        this.currentSearch = this.newEmptySearch()
        this.asyncSearchInProgress = true
        // Results should be updated to show the searching message
        this.updateResults()
      }

      // A search could be requested asynchronously
      try {
        this.currentSearch = await this.getSearchFromText(this.tempSelection.text)
        this.updateResults()
      } catch (error) {
        this.currentSearch = this.newEmptySearch()
      }
    }

    // Finally, emit change
    // TODO: Update TempSelection with a valid model on async versions?
    this.events.emit('change', this.tempSelection)
  }

  /**
   * Handled manually
   *
   * - Persists tempSelection if it's valid & different
   * - Emit SUBMIT if selection has changed
   *
   * @returns {Autocomplete} self instance
   */
  submitSelection () {
    if (!this.tempSelection.model || this.tempSelection.model === this.selection.model) {
      return this
    }

    this.selection = { ...this.tempSelection }
    this.onBlur()
    this.events.emit('submit', this.selection)
    return this
  }

  /**
   * Handled manually
   *
   * - Resets a selection (if it was filled)
   * - Emit SUBMIT if selection has changed
   *
   * @returns {Autocomplete} Self instance
   */
  resetSelection () {
    if (!this.selection.model) {
      return this
    }
    this.selection = {
      text: '',
      model: null,
      resultElement: null
    }
    this.events.emit('submit', this.selection)
    return this
  }

  /**
   * Handled by blur on input
   *
   * - Removes resultsElement
   * - Resets tempSelection && currentSearch
   * - Update input value with selection
   */
  onBlur (ev) {
    this.removeResultsElement()
    this.tempSelection = null
    this.currentSearch = this.newEmptySearch()
    this.updateInputValueFromSelection(this.selection)
    if (this.abortController) {
      this.abortController.abort()
      this.abortController = null
    }
  }

  /**
   * Handled by keyDOWN on input
   *
   * - For UP and DOWN arrow keys
   *   - Select next/previous or first/last result
   *   - Update tempSelection accordingly
   *   - Ensure result visibility
   *   - Emit CHANGE event
   *
   * - For ENTER and TAB keys
   *   - Trigger blur event for submitting action
   *
   * - For ESC key
   *   - Reset tempSelection with last known selection
   *   - Trigger blur event for submitting action
   */
  onKeyDown (ev) {
    // Ignore event if the resultsElement is not connected
    if (!this.resultsElement || !this.resultsElement.isConnected) return

    const key = ev.keyCode

    // Change keys: Down (40), Up (38)
    if (key === 40 || key === 38) {
      const nextElement =
        key === 40
          ? this.tempSelection.resultElement
            ? this.tempSelection.resultElement.nextElementSibling || this.tempSelection.resultElement
            : this.resultsElement.querySelector(`.${this.options.resultClassName}`)
          : this.tempSelection.resultElement
            ? this.tempSelection.resultElement.previousElementSibling || this.tempSelection.resultElement
            : this.resultsElement.querySelector(`.${this.options.resultClassName}:last-child`)
      const newModel = this.getModelFromResultElement(nextElement)
      if (newModel !== this.tempSelection.model) {
        this.tempSelection.model = newModel
        this.unsetSelectionResultElement()
          .setSelectionResultElement()
          .ensureResultVisibility()
        this.events.emit('change', this.tempSelection)
      }
    }

    // Submit keys: Enter (13), Tab (9)
    if (key === 13 || key === 9) {
      if (key === 13 && this.isAsyncSearch() && this.asyncSearchInProgress) {
        return
      }
      if (key === 13 && (!this.tempSelection || !this.tempSelection.model)) {
        return
      }
      this.submitSelection()
      this.textBoxInput.blur()
    }

    // Discard keys: Esc (27)
    if (key === 27) {
      this.textBoxInput.blur()
    }
  }

  /**
   * Handled by keyUP on input
   *
   * - Discard keys handled by keyDOWN event
   * - Call debounced input change function
   */
  onKeyUp (ev) {
    const key = ev.keyCode
    // Discard some keys like arrows, enter, esc, ...
    if (!key || (key >= 35 && key <= 40) || key === 13 || key === 27 || key === 9) return
    this.debouncedInputChange()
  }

  /**
   * Handled by mousemove on resultsElement
   *
   * - Try to find a resultElement candidate traversing the dom
   * - If a candidate is found:
   *   - Emit CHANGE event
   */
  onMouseMove (ev) {
    const resultCandidate = this._findResultByMouseEvent(ev)
    if (!resultCandidate || !this.tempSelection || resultCandidate === this.tempSelection.resultElement) return

    const newModel = this.getModelFromResultElement(resultCandidate)
    if (newModel !== this.tempSelection.model) {
      this.tempSelection.model = newModel
      this.unsetSelectionResultElement().setSelectionResultElement()
      this.events.emit('change', this.tempSelection)
    }
  }

  /**
   * Handled by click on resultsElement
   * - Try to find a resultElement candidate traversing the dom
   * - If a candidate is found:
   *   - Trigger submitSelection
   */
  onMouseClick (ev) {
    const resultCandidate = this._findResultByMouseEvent(ev)
    if (!resultCandidate) return
    this.tempSelection.model = this.getModelFromResultElement(resultCandidate)
    if (this.tempSelection.model) {
      this.unsetSelectionResultElement().setSelectionResultElement()
      this.events.emit('change', this.tempSelection)
      this.submitSelection()
    } else {
      ev.stopPropagation()
    }
  }

  /**
   * Small utility to find a resultElement candidate from mouse event, traversing the dom
   *
   * @returns {(HTMLElement|null)} The resultElement if found
   */
  _findResultByMouseEvent (ev) {
    const closestResult = (el, lastParentToCheck) => {
      do {
        if (el === lastParentToCheck) return null
        if (el.matches(`.${this.options.resultClassName}`)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el !== lastParentToCheck && el.nodeType === 1)
      return null
    }
    return closestResult(ev.target, this.resultsElement)
  }

  /*
   * -----------------------------------------------------
   * TEMPLATING RELATED METHODS
   * -----------------------------------------------------
   * */

  /**
   * Get current HTML for resultsElement wrapper (floatingBox)
   *
   * @returns {String} The HTML string ready to be converted or appended
   */
  getResultsElementHtml () {
    return `
<div
  class="c-floating-box c-floating-box--with-gradient c-floating-box--floats-from@${this.options.floatsFrom} c-autocomplete__floating-box"
  data-c-autocomplete__floating-box=""
  data-js-component="c-floating-box"
  data-c-floating-box__min-height="${this.options.minHeight}"
  data-c-floating-box__max-height="${this.options.maxHeight}">
  <div class="c-floating-box__header"></div>
  <div class="c-floating-box__body">
    <div class="c-floating-box__body-content">
    </div>
  </div>
  <div class="c-floating-box__footer">
    <div class="c-autocomplete__actions u-align--right">
      <button type="button" class="c-btn c-btn--flat-neutral c-autocomplete__cancel" data-c-autocomplete__action="cancel">${this.locales.cancel}</button>
    </div>
  </div>
</div>`
  }

  /**
   * Get current HTML for currentSearch results
   * This method can be overridden on instances if you want custom markup
   *
   * @returns {String} The HTML string ready to be converted or appended
   */
  getResultsHtml () {
    return `
<ul class="c-autocomplete__results c-autocomplete__results--from-search" role="listbox">
  ${this.currentSearch.resultsShown.map(result => this.getResultHtml(result)).join('')}
</ul>
`
  }

  /**
   * Get current HTML for a single currentSearch result
   * This method can be overridden on instances if you want custom markup
   *
   * @returns {String} The HTML string ready to be converted or appended
   */
  getResultHtml (result) {
    return `
<li class="c-autocomplete__result" aria-selected="false" data-cid="${result.cid}">
  ${this.options.displayAttributes
    .map(attr => {
      if (result.getAttribute(attr) && this.options.searchAttributes.includes(attr)) {
        return `
    <span data-attribute="${attr}">
      ${result
        .getAttribute(attr)
        .toString()
        .replace(this.currentSearch.termRegExp, '<mark>$1</mark>')}
    </span>`
      } else if (result.getAttribute(attr)) {
        return `
    <span data-attribute="${attr}">
      ${result.getAttribute(attr).toString()}
    </span>`
      } else {
        return ''
      }
    })
    .join('')}
</li>
`
  }

  /**
   * Get HTML string based on no results
   * This method can be overridden on instances if you want custom markup
   *
   * @returns {String} The HTML string ready to be converted or appended
   */
  getNoResultsHtml () {
    this.events.emit(autocompleteEvents.NO_RESULTS, { term: this.currentSearch.term })
    return `
      <ul class="c-autocomplete__results c-autocomplete__results--from-search" role="listbox">
      ${
        this.currentSearch.term
          ? `<li class="c-autocomplete__result c-autocomplete__result--no-result" aria-selected="false">
          ${this.locales.sorryNoMatches} <mark>${encodeHtml(this.tempSelection.text)}</mark>
        </li>`
          : this.currentSearch.resultsShown
          ? this.currentSearch.resultsShown.map(result => this.getResultHtml(result)).join('')
          : ''
      }
      </ul>
      `
  }

  /**
   * Get HTML string based on searching results
   * Should only be displayed when the results are async and an while an API call is running
   * This method can be overridden on instances if you want custom markup
   *
   * @returns {String} The HTML string ready to be converted or appended
   */
  getSearchingResultsHtml () {
    return `
      <ul class="c-autocomplete__results c-autocomplete__results--from-search" role="listbox">
        ${
          this.tempSelection.text
            ? `<li class="c-autocomplete__result c-autocomplete__result--searching" aria-selected="false">
              ${this.locales.searchingFor} <mark>${encodeHtml(this.tempSelection.text)}</mark> ...
              ${LoaderTemplate({ size: 'tiny', extraClasses: 'c-autocomplete__result-loader' })}
            </li>`
            : ''
        }
      </ul>
      `
  }

  _getSafeText (text) {
    return text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  _getRegExp (safeText) {
    return safeText ? new RegExp('(' + safeText.split(' ').join('|') + ')', 'gi') : null
  }

  attachEvents (element) {
    this.resultsElementEvents = [
      [element, { mousemove: ev => this.onMouseMove(ev) }],
      [element, { mousedown: ev => this.onMouseClick(ev) }, true],
      [element.querySelector('[data-c-autocomplete__action="cancel"]'), { click: ev => this.removeResultsElement(ev) }]
    ]
    domEventsHelper.attachEvents(this.resultsElementEvents, componentAPI)
  }

  detachEvents () {
    if (this.resultsElementEvents) domEventsHelper.detachEvents(this.resultsElementEvents, componentAPI)
  }
}
