
export const da = {
  searchingFor: 'Searching for'
}

export const de = {
  searchingFor: 'Suche nach'
}

export const en = {
  searchingFor: 'Searching for'
}

export const nb = {
  searchingFor: 'Leter etter'
}

export const fr = {
  searchingFor: 'À la recherche de'
}

export const nl = {
  searchingFor: 'Zoeken naar'
}

export const sv = {
  searchingFor: 'Söker efter'
}
