import Component from '../../../js/core/component/component'
import registeredEvents from '../../../js/helpers/registered-events'
import { registerComponent } from '../../../js/core/component/component-directory'
import domEventsHelper from '../../../js/document/dom-events-helper'

const definition = {
  name: 'c-card',
  props: []
}

const componentQueries = {
  titleLinkElement: `.${definition.name}__title-link`,
  titleElement: `.${definition.name}__title`
}

const attr = {
  track: 'data-track'
}

export default class Card extends Component {
  /**
   * Creates a new card, exposes an API to the element.
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    domEventsHelper.attachEvents([[this.element, { click: (ev) => this._onClick(ev) }]], definition.name)
    this.element[definition.name].getTitle = (this.getTitle).bind(this)
    this.element[definition.name].getDataAttributes = (this.getDataAttributes).bind(this)
    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
  * Get data attributes for the card component
  *  @return {Object \ null}
  */
  getDataAttributes () {
    if (!this.element) return
    return this.element.dataset
  }

  /**
  * click event for the card component
  */
  _onClick (ev) {
    const target = ev.currentTarget
    this.events.emit('clickCard', target)
  }

  /**
  * get the title from the card component
  * @return {String}
  */
  getTitle () {
    let titleNode = this.element.querySelector(componentQueries.titleLinkElement)
    if (!titleNode) titleNode = this.element.querySelector(componentQueries.titleElement)
    return titleNode ? titleNode.innerText : ''
  }
}

registerComponent(Card, definition.name, definition)
