import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'

const definition = {

  name: 'c-button-group',
  props: [
    {
      name: 'activeIndex',
      type: 'number'
    },
    {
      name: 'activeElement',
      type: 'HTMLElement'
    },
    {
      name: 'disabled',
      type: 'boolean',
      attr: '.is-disabled',
      defaultValue: false
    }
  ]
}

/**
 * Button Group content
 *
 */
export default class ButtonGroup extends Component {
  /**
   * Creates a new button group behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this._setActiveOnClick()
    const active = this.getActiveIndex()
    this.setProp('activeIndex', active !== -1 ? active : 0)
  }

  getActiveElement () {
    return this._getButtonElements()[this.props.activeIndex]
  }

  getActiveIndex () {
    return this._getButtonElements().findIndex((item) => item.classList.contains('is-active'))
  }

  setActiveIndex (index) {
    this._getButtonElements()
      .forEach((item, itemIndex) => item.classList[itemIndex === index ? 'add' : 'remove']('is-active'))
    const activeElement = this._getButtonElements()[index]
    // We need to force the update, otherwise the JSON.stringify for a dom element will return an empty object avoiding to update the property
    this.setProp('activeElement', activeElement, { forceUpdate: true })
    this.events.emit('changeActiveElement', activeElement)
  }

  _getButtonElements () {
    this.buttonElements = this.buttonElements || Array.from(this.element.querySelectorAll('button'))
    return this.buttonElements
  }

  _setActiveOnClick () {
    this._getButtonElements().forEach((button, index) => button
      .addEventListener('click', e => {
        if (!this.getProp('disabled')) this.setProp('activeIndex', index)
      }))
  }
}

registerComponent(ButtonGroup, definition.name, definition)
