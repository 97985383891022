import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import domEventsHelper from '../../../js/document/dom-events-helper'

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */

const definition = {
  name: 'c-actionable-list',
  props: []
}

const componentAPI = 'c-actionable-list'
const componentQueries = {
  itemElement: `[data-${componentAPI}__item]`,
  itemId: `data-${componentAPI}__item_id`
}

/**
 * ActionableList content
 *
 */
export default class ActionableList extends Component {
  /**
   * Creates a new actionable list behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    const events = []
    const items = this.element.querySelectorAll(componentQueries.itemElement)
    items.forEach(item => {
      events.push([item, { click: (ev) => this.onClick(ev) }])
    })
    domEventsHelper.attachEvents(events, definition.name)
  }

  onClick (ev) {
    const element = ev.currentTarget
    const id = element.getAttribute(componentQueries.itemId)
    this.events.emit('selectItem', id)
  }
}

registerComponent(ActionableList, definition.name, definition)
