/**
 * The defaultAccordionData contains all data needed to hydrate an Accordion  view
 *
 * @typedef {Object}          Accordion
 *
 * @property {String}         id                  - id
 * @property {String}         size                - Accordion size
 * @property {String}         extraClasses       - Accordion extraClasses
 */

import { getTokenClass } from '../../../js/helpers/tokens'
import { AccordionItemTemplate } from './c-accordion__item.template'

// Import tokens
const tokensShared = require('./data/c-accordion__tokens.json')['c-accordion']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/accordion/data/c-accordion__tokens.json`)['c-accordion'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultAccordionData = {
  id: 0,
  size: '',
  extraClasses: '',
  items: []
}

export const AccordionTemplate = (d) => {
  d = { ...defaultAccordionData, ...d }

  return `
  <div class="c-accordion ${getTokenClass('size', d.size || 'default', tokens)} ${d.extraClasses}"
    data-c-accordion__id="${d.id}"> 
      ${d.items.map((item) => AccordionItemTemplate({ size: d.size, ...item })).join('')}
  </div>
  `
}
