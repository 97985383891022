import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'

const componentAPI = 'c-absolute-dialog'
const definition = {
  name: 'c-absolute-dialog'
}

/**
 * Absolute dialog
 *
 */
export default class AbsoluteDialog extends Component {
  /**
   * Creates a new AbsoluteDialog behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    this.button = (this.element.querySelector(`[data-js-component*="${componentAPI}--button"]`))

    this.bindEvents()
  }

  /**
   * Binds the events for the component behaviour
   */
  bindEvents () {
    if (this.button) {
      this.button.addEventListener('click', (info) => {
        this.element.classList.add('is-hidden')
      })
    }
    // end of fade away animation
    this.element.addEventListener('webkitTransitionEnd', this.closeDialogIfIsHidden.bind(this))
    this.element.addEventListener('msTransitionEnd', this.closeDialogIfIsHidden.bind(this))
    this.element.addEventListener('oTransitionEnd', this.closeDialogIfIsHidden.bind(this))
    this.element.addEventListener('otransitionend', this.closeDialogIfIsHidden.bind(this))
    this.element.addEventListener('transitionend', this.closeDialogIfIsHidden.bind(this))
  }

  /**
  * Checks if dialog have the is-hidden class
  */
  closeDialogIfIsHidden () {
    if (this.element.classList.contains('is-hidden')) {
      this.closeDialog()
    }
  }

  /**
  * Removes the element
  */
  closeDialog () {
    this.element.parentNode.removeChild(this.element)
  }
}

registerComponent(AbsoluteDialog, definition.name, definition)
