import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import box tokens
const tokensShared = require('./data/c-absolute-dialog__tokens.json')['c-absolute-dialog']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/box/data/c-absolute-dialog__tokens.json`)['c-absolute-dialog'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The defaultAbsoluteDialogData contains all data needed to hydrate an absolute dialog view
 *
 * @typedef {Object}          defaultAbsoluteDialogData
 * @property {String}         id                - SelectedState's id
 * @property {String}         title             - Absolute dialog title
 * @property {String}         body              - Absolute dialog content
 * @property {Boolean}        closable          - True or false
 * @property {String}         alertType         - Alert type of the dialog
 * @property {String}         dialogType        - Dialog type of the dialog
 * @property {Object}         [attributes]      - Attributes for the button
 * @property {String}         [extraContent]    - Extra content (html)
 */
export const defaultAbsoluteDialogData = {
  id: '',
  title: '',
  body: '',
  closable: true,
  alertType: 'default',
  dialogType: 'default',
  headerSize: 'default',
  attributes: {},
  extraContent: ''
}

export const AbsoluteDialogTemplate = (d) => {
  d = { ...defaultAbsoluteDialogData, ...d }
  return `
    <div id="${d.id}" class="c-absolute-dialog
         ${getTokenClass('alertType', d.alertType, tokens)}
         ${getTokenClass('dialogType', d.dialogType, tokens)}
         ${d.closable ? 'c-absolute-dialog--closable' : ''}"
         data-js-component="c-absolute-dialog" ${d.attributes ? encodeAttributes(d.attributes) : ''}> ` +
    `${d.title
      ? `<div class="c-absolute-dialog__header">
        <h2 class="c-absolute-dialog__title m-heading ${getTokenClass('header__size', d.headerSize, tokens)}">
          <span class="c-absolute-dialog__title-icon m-icon--${getTokenClass('icon', d.alertType, tokens)}"></span>
          <span class="c-absolute-dialog__title-text">${d.title}</span>
        </h2>
      </div>`
      : ''}` +
    `${d.closable
      ? '<span class="m-icon--cross c-absolute-dialog__close" data-js-component="c-absolute-dialog--button"></span>'
      : ''}` +
    `<div class="c-absolute-dialog__content  m-body--small">
        <div class="c-absolute-dialog__body sp-stack-children--small">${d.body}</div>
     </div>
     ${d.extraContent}
    </div>
    `
}
