import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { LoaderTemplate } from '../loader/c-loader.template'
import { elementFromString } from '../../../js/document/html-helper'
import domEventsHelper from '../../../js/document/dom-events-helper'
import registeredEvents from '../../../js/helpers/registered-events'

const buttonAPI = 'c-btn'
const componentQueries = {
  textElement: `.${buttonAPI}__text`
}

/**
 * Props & State
 *
 * There are two types of data that control a component: props and state.
 * Props are set on instantiation and they are fixed throughout the lifetime
 * of a component. For data that is going to change, we have to use state.
 */
const definition = {
  name: buttonAPI,

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'block',
      type: 'boolean',
      attr: '.c-btn--block',
      defaultValue: false
    }, {
      name: 'disabled',
      type: 'boolean',
      attr: 'disabled',
      defaultValue: false
    }, {
      name: 'icon',
      type: 'string'
    }, {
      name: 'iconPosition',
      type: 'string',
      allowedValues: [
        'left',
        'right'
      ],
      defaultValue: 'left'
    }, {
      name: 'loading',
      type: 'boolean',
      defaultValue: false
    }, {
      name: 'text',
      type: 'string'
    }, {
      name: 'variant',
      type: 'string',
      attr: '.c-btn--',
      allowedValues: [
        'default',
        'flat',
        'flow',
        'icon-only',
        'neutral',
        'delete'
      ],
      defaultValue: 'default'
    }
  ]

}

const attr = {
  track: 'data-track'
}

/**
 * Button content
 *
 */
export default class Btn extends Component {
  /**
   * Creates a new button behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)
    domEventsHelper.attachEvents([
      [this.element, { click: (ev) => this._onClick(ev) }]
    ], definition.name)
    this.textNode = undefined

    this.eventData = this._getEventData()
    element[definition.name] = {
      ...element[definition.name],
      getText: this.getText.bind(this),
      setText: this.setText.bind(this),
      getIcon: this.getIcon.bind(this),
      setIcon: this.setIcon.bind(this),
      getIconPosition: this.getIconPosition.bind(this),
      setIconPosition: this.setIconPosition.bind(this),
      getLoading: this.getLoading.bind(this),
      setLoading: this.setLoading.bind(this),
      getDataAttributes: this.getDataAttributes.bind(this)
    }

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  getText () {
    const textNode = this._getTextNode(this.element)
    return textNode ? textNode.innerText : ''
  }

  setText (value) {
    const textNode = this._getTextNode(this.element)
    if (textNode) { textNode.innerText = value }
  }

  getIcon () {
    const iconNode = this._getIconNode(this.element)
    const iconName = iconNode ? [...iconNode.classList].filter(className => className.includes('m-icon--'))[0]?.split('m-icon--')[1] : null
    return (iconName && iconName !== '') ? iconName : null
  }

  setIcon (value) {
    this._getIconNode(this.element).classList.replace(`m-icon--${this.props.icon}`, `m-icon--${value}`)
  }

  getIconPosition () {
    return this._getIconNode(this.element) === this.element.firstChild ? 'left' : 'right'
  }

  setIconPosition (value) {
    this.element.appendChild(value === 'left' ? this._getTextNode(this.element) : this._getIconNode(this.element))
    const oldValue = (value === 'left') ? 'right' : 'left'
    this._getIconNode(this.element).classList.replace(`c-btn__icon--${oldValue}`, `c-btn__icon--${value}`)
  }

  getLoading () {
    return (this._getLoadingNode(this.element).parentElement === this.element)
  }

  setLoading (loading) {
    const loadingNode = this._getLoadingNode(this.element)

    if (loadingNode) {
      if (loading) {
        this.element.appendChild(loadingNode)
        this.element.classList.add('c-btn--loading')
      } else {
        this.element.removeChild(loadingNode)
        this.element.classList.remove('c-btn--loading')
      }
    }
  }

  getDataAttributes () {
    return this.element.dataset
  }

  _getEventData () {
    const eventData = {}
    if (this.element) {
      eventData.attr = this.element.dataset
    }
    return eventData
  }

  _onClick (ev) {
    const input = ev.currentTarget
    this.events.emit('clickButton', input, this._getEventData())
  }

  _getTextNode () {
    this.textNode = this.textNode || this.element.querySelector(componentQueries.textElement)
    return this.textNode
  }

  _getIconNode () {
    this.iconNode = this.iconNode || this.element.querySelector('.c-btn__icon')
    return this.iconNode
  }

  _getLoadingNode () {
    this.loadingNode = this.loadingNode ||
      this.element.querySelector('.c-btn__loader') ||
      elementFromString(LoaderTemplate({ size: 'tiny', extraClasses: 'c-btn__loader' }))
    return this.loadingNode
  }
}

registerComponent(Btn, definition.name, definition)
